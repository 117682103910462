import React from "react";
import "./TreeCharacteristicsGlossary.css";
import { HashLink } from "react-router-hash-link";
import parse from "react-html-parser";
import {
  getAllGlossaryTerms,
  createGlossaryTerm,
  editGlossaryTerm,
  deleteGlossaryTerm,
  uploadGlossaryPhoto,
  deleteGlossaryPhoto,
  updateGlossaryPhotoText,
} from "../../actions/glossary";
import {
  FaTrashAlt,
  FaPencilAlt,
  FaBold,
  FaItalic,
  FaUnderline,
  FaListOl,
  FaLink,
} from "react-icons/fa";

class Glossary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      showEditModal: false,
      editingEntry: null,
      editingDefinition: "",
      showCreateModal: false,
      newTerm: "",
      newDefinition: "",
    };
    this.editorRef = React.createRef();
    this.createEditorRef = React.createRef();
  }

  async componentDidMount() {
    try {
      const entries = await getAllGlossaryTerms();
      entries.sort((a, b) => a.term.localeCompare(b.term));
      this.setState({ entries });

      const hash = decodeURIComponent(window.location.hash).substring(1);
      if (hash) {
        const element = document.getElementById(hash.replaceAll(" ", "_"));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  openEditModal = entry => {
    this.setState(
      {
        showEditModal: true,
        editingEntry: entry,
        editingDefinition: entry.definition,
      },
      () => {
        if (this.editorRef.current) {
          this.editorRef.current.innerHTML = entry.definition;
        }
      }
    );
  };

  openCreateModal = () => {
    this.setState(
      {
        showCreateModal: true,
        newTerm: "",
        newDefinition: "",
      },
      () => {
        if (this.createEditorRef.current) {
          this.createEditorRef.current.innerHTML = "";
        }
      }
    );
  };

  handleCreateCancel = () => {
    this.setState({
      showCreateModal: false,
      newTerm: "",
      newDefinition: "",
    });
  };

  handleAddLinkEdit = () => {
    const url = prompt(
      "Enter the URL for the link (must start with https://):"
    );
    if (url) {
      if (/^https:\/\//i.test(url)) {
        this.execCommand("createLink", url);
      } else {
        alert("Invalid URL. Please ensure it starts with https://");
      }
    }
  };

  handleAddLinkCreate = () => {
    const url = prompt(
      "Enter the URL for the link (must start with https://):"
    );
    if (url) {
      if (/^https:\/\//i.test(url)) {
        this.execCommandCreate("createLink", url);
      } else {
        alert("Invalid URL. Please ensure it starts with https://");
      }
    }
  };

  handleAddLinkPhotoText = photoEditorRef => {
    const url = prompt(
      "Enter the URL for the link (must start with https://):"
    );
    if (!url) return;

    if (!/^https:\/\//i.test(url)) {
      alert("Invalid URL. Please ensure it starts with https://");
      return;
    }

    this.execCommandPhotoText("createLink", photoEditorRef, url);
  };

  handleCreateTerm = async () => {
    const { newTerm, entries } = this.state;

    const newDefinition = this.createEditorRef.current.innerHTML;

    if (!newTerm.trim()) {
      alert("Please enter a term");
      return;
    }

    if (!newDefinition.trim()) {
      alert("Please enter a definition");
      return;
    }

    try {
      const createdTerm = await createGlossaryTerm({
        term: newTerm.trim(),
        definition: newDefinition,
      });

      this.setState({
        entries: [...entries, createdTerm].sort((a, b) =>
          a.term.localeCompare(b.term)
        ),
        showCreateModal: false,
        newTerm: "",
        newDefinition: "",
      });

      alert("New glossary term created!");
    } catch (error) {
      console.error("Failed to create new glossary term:", error);
      alert("Failed to create new glossary term.");
    }
  };

  handleSave = async () => {
    const { editingEntry } = this.state;
    const newDefinition = this.editorRef.current.innerHTML;

    if (!newDefinition.trim()) {
      alert("Please enter a definition");
      return;
    }

    try {
      await editGlossaryTerm(editingEntry.glossary_id, {
        term: editingEntry.term,
        definition: newDefinition,
      });
      this.setState(prevState => ({
        entries: prevState.entries.map(e =>
          e.glossary_id === editingEntry.glossary_id
            ? { ...e, definition: newDefinition }
            : e
        ),
        showEditModal: false,
        editingEntry: null,
        editingDefinition: "",
      }));
      alert("Entry updated successfully!");
    } catch (error) {
      alert("Failed to update entry.");
    }
  };

  handleCancel = () => {
    this.setState({
      showEditModal: false,
      editingEntry: null,
      editingDefinition: "",
    });
  };

  handleEdit = entry => {
    this.openEditModal(entry);
  };

  handleDelete = async id => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      try {
        await deleteGlossaryTerm(id);
        this.setState(prevState => ({
          entries: prevState.entries.filter(entry => entry.glossary_id !== id),
        }));
        alert("Entry deleted successfully!");
      } catch (error) {
        alert("Failed to delete entry.");
      }
    }
  };

  handlePhotoUpload = async (glossaryId, event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      await uploadGlossaryPhoto(glossaryId, file);
      const entries = await getAllGlossaryTerms();
      entries.sort((a, b) => a.term.localeCompare(b.term));
      this.setState({ entries });
      alert("Photo uploaded successfully!");
    } catch (error) {
      alert("Failed to upload photo.");
    }
  };

  handlePhotoDelete = async (photoId, glossaryId) => {
    if (window.confirm("Are you sure you want to delete this photo?")) {
      try {
        await deleteGlossaryPhoto(photoId);
        this.setState(prevState => ({
          entries: prevState.entries.map(entry =>
            entry.glossary_id === glossaryId
              ? {
                  ...entry,
                  photos: entry.photos.filter(
                    photo => photo.glossary_photo_id !== photoId
                  ),
                }
              : entry
          ),
        }));
        alert("Photo deleted successfully!");
      } catch (error) {
        alert("Failed to delete photo.");
      }
    }
  };

  handlePhotoTextUpdate = async (photoId, glossaryId, editorRef) => {
    const newText = editorRef.current.innerHTML;
    try {
      await updateGlossaryPhotoText(photoId, newText);
      this.setState(prevState => ({
        entries: prevState.entries.map(entry =>
          entry.glossary_id === glossaryId
            ? {
                ...entry,
                photos: entry.photos.map(photo =>
                  photo.glossary_photo_id === photoId
                    ? { ...photo, definition_text: newText }
                    : photo
                ),
              }
            : entry
        ),
      }));
    } catch (error) {
      alert("Failed to update photo text.");
    }
  };

  execCommand = (command, value = null) => {
    document.execCommand(command, false, value);
    this.editorRef.current.focus();
  };

  execCommandCreate = (command, value = null) => {
    document.execCommand(command, false, value);
    this.createEditorRef.current.focus();
  };

  execCommandPhotoText = (command, editorRef, value = null) => {
    document.execCommand(command, false, value);
    editorRef.current.focus();
  };

  createPhotoEditorRef = () => React.createRef();

  render() {
    const { entries, showEditModal, showCreateModal, newTerm } = this.state;
    const { adminView = false } = this.props;
    const letters = [];
    entries.forEach(entry => {
      const firstChar = entry.term[0].toUpperCase();
      if (!letters.includes(firstChar)) {
        letters.push(firstChar);
      }
    });

    return (
      <>
        {showEditModal && adminView && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Edit Definition</h2>
              <div className="editor-toolbar">
                <button onClick={() => this.execCommand("bold")} title="Bold">
                  <FaBold />
                </button>
                <button
                  onClick={() => this.execCommand("italic")}
                  title="Italic"
                >
                  <FaItalic />
                </button>
                <button
                  onClick={() => this.execCommand("underline")}
                  title="Underline"
                >
                  <FaUnderline />
                </button>
                <button
                  onClick={() => this.execCommand("insertOrderedList")}
                  title="Ordered List"
                >
                  <FaListOl />
                </button>
                <button
                  onClick={() => this.handleAddLinkEdit()}
                  title="Add Link"
                >
                  <FaLink />
                </button>
              </div>
              <div
                ref={this.editorRef}
                className="rich-text-editor"
                contentEditable={true}
                suppressContentEditableWarning={true}
              />
              <div className="modal-actions">
                <button onClick={this.handleSave}>Save</button>
                <button onClick={this.handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {showCreateModal && adminView && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Add New Term</h2>
              <div className="form-group">
                <label htmlFor="newTerm">Term:</label>
                <input
                  type="text"
                  id="newTerm"
                  style={{ marginLeft: "0.2rem" }}
                  value={newTerm}
                  onChange={e => this.setState({ newTerm: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Definition:</label>
                <div className="editor-toolbar">
                  <button
                    onClick={() => this.execCommandCreate("bold")}
                    title="Bold"
                  >
                    <FaBold />
                  </button>
                  <button
                    onClick={() => this.execCommandCreate("italic")}
                    title="Italic"
                  >
                    <FaItalic />
                  </button>
                  <button
                    onClick={() => this.execCommandCreate("underline")}
                    title="Underline"
                  >
                    <FaUnderline />
                  </button>
                  <button
                    onClick={() => this.execCommandCreate("insertOrderedList")}
                    title="Ordered List"
                  >
                    <FaListOl />
                  </button>
                  <button
                    onClick={() => this.handleAddLinkCreate()}
                    title="Add Link"
                  >
                    <FaLink />
                  </button>
                </div>
                <div
                  ref={this.createEditorRef}
                  className="rich-text-editor"
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                />
              </div>
              <div className="modal-actions">
                <button onClick={this.handleCreateTerm}>Create</button>
                <button onClick={this.handleCreateCancel}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        <div className="id-space" id="content"></div>
        <div className="tree-characteristic-glossary container">
          <h1>Tree Characteristics Glossary</h1>
          <div>
            <h3>
              {letters.map(letter => (
                <HashLink
                  key={letter}
                  to={`/tree-characteristics-glossary#${letter}`}
                >
                  {letter}{" "}
                </HashLink>
              ))}
            </h3>
          </div>

          <div>
            {entries.map((entry, i) => {
              const currentLetter = entry.term[0].toUpperCase();
              const prevLetter =
                i > 0 ? entries[i - 1].term[0].toUpperCase() : null;
              const startLetter = currentLetter !== prevLetter;

              return (
                <div key={entry.glossary_id}>
                  {startLetter && (
                    <div id={currentLetter} className="id-space"></div>
                  )}
                  <div
                    id={entry.term.replaceAll(" ", "_")}
                    className="tree-characteristic-glossary-entry"
                  >
                    <div className="tree-characteristic-glossary-title">
                      <h3>
                        <HashLink
                          to={`/tree-characteristics-glossary#${entry.term.replaceAll(
                            " ",
                            "_"
                          )}`}
                        >
                          {entry.term}
                        </HashLink>
                      </h3>
                      {adminView && (
                        <span className="glossary-entry-actions">
                          <FaPencilAlt
                            className="edit-icon"
                            onClick={() => this.handleEdit(entry)}
                          />
                          <FaTrashAlt
                            className="delete-icon"
                            onClick={() => this.handleDelete(entry.glossary_id)}
                          />
                        </span>
                      )}
                    </div>
                    <div className="tree-characteristic-glossary-text">
                      {parse(entry.definition)}
                      <div className="glossary-photos">
                        {entry.photos &&
                          entry.photos.length > 0 &&
                          entry.photos.map(photo => (
                            <div
                              key={photo.glossary_photo_id}
                              className="glossary-photo-container"
                            >
                              <img
                                src={`${process.env.REACT_APP_BASE_IMAGE_URL}${photo.s3_link}`}
                                alt={`Illustration for ${entry.term}`}
                                className="glossary-photo"
                              />
                              {adminView && (
                                <>
                                  <div className="photo-text-editor-container">
                                    <div className="editor-toolbar photo-editor-toolbar">
                                      <button
                                        onClick={() =>
                                          this.execCommandPhotoText(
                                            "bold",
                                            photo.editorRef
                                          )
                                        }
                                        title="Bold"
                                      >
                                        <FaBold />
                                      </button>
                                      <button
                                        onClick={() =>
                                          this.execCommandPhotoText(
                                            "italic",
                                            photo.editorRef
                                          )
                                        }
                                        title="Italic"
                                      >
                                        <FaItalic />
                                      </button>
                                      <button
                                        onClick={() =>
                                          this.execCommandPhotoText(
                                            "underline",
                                            photo.editorRef
                                          )
                                        }
                                        title="Underline"
                                      >
                                        <FaUnderline />
                                      </button>
                                      <button
                                        onClick={() =>
                                          this.handleAddLinkPhotoText(
                                            photo.editorRef
                                          )
                                        }
                                        title="Add Link"
                                      >
                                        <FaLink />
                                      </button>
                                    </div>
                                    <div
                                      ref={
                                        photo.editorRef ||
                                        (photo.editorRef =
                                          this.createPhotoEditorRef())
                                      }
                                      className="rich-text-editor photo-text-input"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={() =>
                                        this.handlePhotoTextUpdate(
                                          photo.glossary_photo_id,
                                          entry.glossary_id,
                                          photo.editorRef
                                        )
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: photo.definition_text || "",
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="delete-photo-button"
                                    onClick={() =>
                                      this.handlePhotoDelete(
                                        photo.glossary_photo_id,
                                        entry.glossary_id
                                      )
                                    }
                                  >
                                    <FaTrashAlt />
                                  </button>
                                </>
                              )}
                              {!adminView && photo.definition_text && (
                                <div className="photo-text-display">
                                  {parse(photo.definition_text)}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                      {adminView && (
                        <div className="photo-controls">
                          <div className="photo-upload-container">
                            <input
                              type="file"
                              id={`photo-upload-${entry.glossary_id}`}
                              accept="image/jpeg,image/png"
                              style={{ display: "none" }}
                              onChange={e =>
                                this.handlePhotoUpload(entry.glossary_id, e)
                              }
                            />
                            <label
                              htmlFor={`photo-upload-${entry.glossary_id}`}
                              className="upload-photo-button"
                            >
                              Add Photo
                            </label>
                          </div>
                          <div className="back-to-top">
                            <HashLink
                              to={`/tree-characteristics-glossary#content`}
                            >
                              Back to Top
                            </HashLink>
                          </div>
                        </div>
                      )}
                      {!adminView && (
                        <div className="back-to-top">
                          <HashLink
                            to={`/tree-characteristics-glossary#content`}
                          >
                            Back to Top
                          </HashLink>
                        </div>
                      )}
                    </div>
                    <hr />
                  </div>
                </div>
              );
            })}
          </div>
          {adminView && (
            <div className="add-term-container">
              <button
                className="add-new-term-button"
                onClick={this.openCreateModal}
              >
                Add New Term
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Glossary;
